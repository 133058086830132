<template>
    <div class="dashboard">
      <SidebarComponent />
      <div class="argon-content">
        <TopbarComponent />
        <MainComponent>  
          <AlertComponent />
            <PerusahaanComponent />
          
        </MainComponent>
    
      </div>
    </div>
  </template>
  
  <script>
  import TopbarComponent from '@/components/dashboard/TopbarComponent.vue';
  import SidebarComponent from '@/components/dashboard/SidebarComponent.vue';
  import MainComponent from '@/components/dashboard/MainComponent.vue';
  import PerusahaanComponent from '@/components/company/PerusahaanComponent.vue';
  import AlertComponent from '@/components/dashboard/AlertComponent.vue';
  
  export default {
    name: 'DashboardView',
    components: {
      TopbarComponent,
      SidebarComponent,
      MainComponent,
      PerusahaanComponent,
      AlertComponent,
    }
  };
  </script>
  
  <style scoped>
  .dashboard {
    display: flex;
    height: 100vh; /* Ensure full height of viewport */
    margin: 0;
    padding: 0;
  }
  
  .argon-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure content takes full height */
    margin: 0;
    padding: 0;
  }
  
  </style>
  