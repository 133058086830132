<template>
    <div class="container-fluid text-white footer mt-5 pt-5" style="background: rgb(251,216,107);
background: linear-gradient(321deg, rgba(251,216,107,1) 0%, rgba(74,101,175,1) 50%, rgba(0,0,0,1) 100%);">
      <div class="container py-5 mb-5">
        <div class="row gy-5 gx-4 pt-5">
          <div class="col-lg-8">
            
            <div class="d-flex align-items-center">
         <a href="https://jakarta-tourism.go.id/" target="_blank" class="d-flex align-items-center">
            <H2 class="fw-bold text-white">Laporan Kegiatan Usaha</H2>
        </a>
   
  </div>
  <br>  

<center>
            <div class="row mt-3" style="">
    <div class="col-lg-2 col-md-4 col-sm-6 col-6 mb-3">
        <a href="https://jakarta.go.id/" target="_blank">
            <img src="../../assets/images/dkijkarta.png" class="img-fluid desktop" alt="DKI Jakarta" style="max-height: 65%;">
            <img src="../../assets/images/dkijkarta.png" class="img-fluid mobile" alt="DKI Jakarta" style="max-height: 80%;">
            
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6 col-6 mb-3">
        <a href="https://jakarta-tourism.go.id/" target="_blank">
            <img src="../../assets/images/enjoy.png" class="img-fluid desktop" alt="Enjoy" style="max-width: 170%;">
            <img src="../../assets/images/enjoy.png" class="img-fluid mobile" alt="Enjoy" style="max-height: 80%;margin-left:-50px">
        </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6 col-6 mb-3">
        <a href="https://www.jktcreative.co/" target="_blank">
          <img src="../../assets/images/jktcreative.png" class="img-fluid desktop" alt="JKT Creative" style="max-height: 70%; margin-left: 70px;">
            <img src="../../assets/images/jktcreative.png" class="img-fluid mobile" alt="JKT Creative" style="max-height: 80%;">
         </a>
    </div>
    <div class="col-lg-2 col-md-4 col-sm-6 col-6 mb-3">
        <a href="https://www.jakarta.go.id/" target="_blank">
            <img src="../../assets/images/suksesjkt.png" class="img-fluid desktop" alt="Sukses JKT" style="max-width: 150%;margin-left:70px;">
            <img src="../../assets/images/suksesjkt.png" class="img-fluid mobile" alt="Sukses JKT" style="max-height: 80%;margin-left:-50px">
        </a>
    </div>
</div>

<br><br> </center>
          </div>
       







          <div class="col-lg-4">
            <h5 class="fw-bold text-white mb-4">HUBUNGI KAMI</h5>
            <p class="mb-2">Jl. Kuningan Barat Raya No.2, RT.1/RW.1, Kuningan Bar.,
Kec. Mampang Prpt., Kota Jakarta Selatan,
Daerah Khusus Ibukota Jakarta 12710
Indonesia.</p>
            <p class="mb-2"><b-icon-telephone class="mr-2"></b-icon-telephone> (021) 5205455</p>
            <p class="mb-2"><b-icon-envelope class="mr-2"></b-icon-envelope> disparekraf@jakarta.go.id</p>
            <div class="d-flex pt-2">
              <a class="btn btn-outline-light btn-social me-2" href=""> <b-icon-instagram></b-icon-instagram></a>
              <a class="btn btn-outline-light btn-social me-2" href=""> <b-icon-facebook></b-icon-facebook></a>
              <a class="btn btn-outline-light btn-social me-2" href=""> <b-icon-youtube></b-icon-youtube></a>
              <a class="btn btn-outline-light btn-social me-2" href=""> <b-icon-linkedin></b-icon-linkedin></a>
              
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="copyright">
         
          <div class="row">
           
            <div class="col-md-8 text-start mb-3 mb-md-0">
             
              Copyright © 2024 Dinas Pariwisata dan Ekonomi Kreatif Daerah Khusus Jakarta
            </div>
            <div class="col-md-4 text-start text-md-end">
              <div class="footer-menu">
                <a href="">Syarat Ketentuan</a>
                <a href="">Kebijakan Privasi</a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "FooterComponent",
  };
  </script>
  
  <style scoped>
  .footer {
    background-color: #000;
    color: white;
    padding: 20px 0;
  }
  
  .footer .btn-link {
    color: white;
    text-decoration: none;
  }
  
  .footer .btn-link:hover {
    text-decoration: underline;
  }
  
  .footer .btn-social {
    margin-right: 10px;
  }
  
  .bg-dark {
    background-color: #000 !important;
  }
  
  .text-white {
    color: #ffffff !important;
  }
  
  .text-start {
    text-align: left !important;
  }
  
  .border-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  
  .footer-menu a {
    color: white;
    margin-right: 15px;
    text-decoration: none;
  }
  
  .footer-menu a:hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: 767px) {
        .desktop { display: none; }
        .mobile { display: inline-block; }
    }
    @media only screen and (min-width: 768px) {
        .desktop { display: inline-block; }
        .mobile { display: none; }
    }
  </style>
  