<template>
  <div class="container-fluid mt-3">
    <b-button variant="outline-primary" class="mb-3" @click="goBack"><i class="bi bi-arrow-left-circle-fill"></i> Kembali</b-button>
    <br>
    <div class="card shadow mb-4">
      <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">Formulir Data Laporan</h6>
      </div>
      <div class="card-body">
         <!-- <pre>{{ unitUsahaData.laporan_lku }}</pre>  -->
         
        <form @submit.prevent="submitForm">
          <!-- List data yang sudah digunakan -->
    
         

<!-- Form untuk tahun pelaporan dan semester -->
<div class="form-group">
  <label for="tahun_pelaporan">Tahun Pelaporan <small style="color: red;">*</small></label>
  <select
    v-model="formData.tahun_pelaporan"
    class="form-control"
    id="tahun_pelaporan"
    @change="checkExistingLaporan"
    required
  >
    <option disabled value="">Pilih Tahun</option>
    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
  </select>
</div>

<div class="form-group">
  <label for="semester">Semester <small style="color: red;">*</small></label>
  <select
    class="form-control"
    id="semester"
    v-model="formData.semester"
    @change="checkExistingLaporan"
    required
  >
    <option disabled value="">Pilih Semester</option>
    <option value="1">Semester 1</option>
    <option value="2">Semester 2</option>
  </select>
</div>

<!-- Alert jika data sudah digunakan -->
<div v-if="laporanExists" class="alert alert-danger">
  Data tahun {{ formData.tahun_pelaporan }} semester {{ formData.semester }} sudah digunakan.
</div>

          <div class="form-group">
            <label for="tanggal_pelaporan">Tanggal Pelaporan <small style="color: red;">*</small></label>
            <input
        type="date"
        class="form-control"
        id="tanggal_pelaporan"
        v-model="formData.tanggal_pelaporan"
        name="tanggal_pelaporan"
        :max="today"
      />
          </div>

          <fieldset>
            <legend style="font-size:larger;">Jumlah Pengunjung<small style="color: red;">*</small></legend>
            <div class="form-row">
              <div class="form-group col-lg-4" v-for="(bulan, index) in 6" :key="index">
                <label :for="'pengunjung_bulan_' + (index + 1)">Pengunjung Bulan {{ index + 1 }} <small style="color: red;">*</small></label>
                <input
                  type="number"
                  class="form-control"
                  :id="'pengunjung_bulan_' + (index + 1)"
                  v-model="formData['pengunjung_bulan_' + (index + 1)]"
                  placeholder="Masukan Jumlah Pengunjung"
                  required
                />
              </div>
            </div>
          </fieldset>

          <div class="form-group">
            <label for="luas_tanah">Luas Tanah (m²) <small style="color: red;">*</small></label>
            <input
              type="number"
              step="0.01"
              class="form-control"
              id="luas_tanah"
              placeholder="Contoh: 104,5"
              v-model="formData.luas_tanah"
              required
            />
          </div>

          <div class="form-group">
            <label for="luas_bangunan">Luas Bangunan (m²) <small style="color: red;">*</small></label>
            <input
              type="number"
              step="0.01"
              class="form-control"
              id="luas_bangunan"
              placeholder="Contoh: 104,5"
              v-model="formData.luas_bangunan"
              required
            />
          </div>

          <div class="form-group">
            <label for="jumlah_lantai">Jumlah Lantai <small style="color: red;">*</small></label>
            <input
              type="number"
              class="form-control"
              id="jumlah_lantai"
              placeholder="Masukan Jumlah Lantai"
              v-model="formData.jumlah_lantai"
              required
            />
          </div>
          <fieldset>      
  <!-- Single Form-->
  <div v-if="forms.single_forms.length">
    <div v-for="form in forms.single_forms" :key="form.single_form_id">
      
      <!-- Label -->
      <label :for="form.slug">{{ form.label }} 
        <small v-if="form.required" style="color: red;">*</small>
      </label>
      
      
      <!-- Select Input -->
      <select
        v-if="form.tipe_inputan.tipe_inputan === 'select'"
        class="form-control"
        :id="form.slug"
        v-model="formData[form.slug]">
        <option disabled value="">{{ form.placeholder }}</option>
        <option v-for="option in form.options" :key="option.option_id" :value="option.option_id">
          {{ option.value }}
        </option>
      </select>

      <!-- Number Input -->
      <input
        v-if="form.tipe_inputan.tipe_inputan === 'number'"
        type="number"
        class="form-control"
        :id="form.slug"
        :placeholder="form.placeholder"
        v-model="formData[form.slug]"
        />
        <!-- Text Input -->
        <input
        v-if="form.tipe_inputan.tipe_inputan === 'text'"
        type="text"
        class="form-control"
        :id="form.slug"
        :placeholder="form.placeholder"
        v-model="formData[form.slug]"
      />
      <!-- Checkbox Input -->
<div v-if="form.tipe_inputan.tipe_inputan === 'checkbox'">
  <div v-for="option in form.options" :key="option.option_id" class="form-check">
    <input
      type="checkbox"
      class="form-check-input"
      :id="`${form.slug}_${option.option_id}`"
      :value="option.option_id"
      v-model="formData[form.slug]"
    />
    <label class="form-check-label" :for="`${form.slug}_${option.option_id}`">
      {{ option.value }}
    </label>
  </div>
</div>


      <!-- Add other input types as needed -->
    </div>
  </div>
</fieldset>

<fieldset>
   <!-- Form Grup -->
  <div v-if="forms.grup_forms.length">
    <div v-for="group in forms.grup_forms" :key="group.grup_id" class="form-group mt-2">
      <legend style="font-size: larger;">{{ group.nama_grup }}</legend>

      <div v-for="form in group.forms" :key="form.single_form_id">
        <label :for="form.slug">
          {{ form.label }} 
          <small v-if="form.required" style="color: red;">*</small>
        </label>

        <!-- Inputan tipe text -->
        <input
          v-if="form.tipe_inputan.tipe_inputan === 'text'"
          type="text"
          class="form-control"
          :id="form.slug"
          :placeholder="form.placeholder"
          v-model="formData[form.slug]"
          :required="form.required"
        />

        <!-- Inputan tipe select -->
        <select
          v-if="form.tipe_inputan.tipe_inputan === 'select'"
          class="form-control"
          :id="form.slug"
          v-model="formData[form.slug]"
          :required="form.required"
        >
          <!-- Placeholder option -->
          <option disabled value="">{{ form.placeholder }}</option>

          <!-- List of options -->
          <option v-for="option in form.options" :key="option.option_id" :value="option.option_id">
            {{ option.value }}
          </option>
        </select>
      </div>
    </div>
  </div>
</fieldset>

<fieldset>
  <!-- Sub Group -->
  <div v-if="forms.sub_grup_forms.length">
    <div v-for="grup in forms.sub_grup_forms" :key="grup.grup_id" class="form-group mt-2">
      <legend style="font-size: larger;">{{ grup.nama_grup }}</legend>

      <div v-for="subGrup in grup.sub_grup" :key="subGrup.sub_grup_id" class="sub-group mt-2">
        <legend style="font-size: larger;">{{ subGrup.nama_sub_grup }}</legend>

        <div v-for="form in subGrup.forms" :key="form.slug">
          <label :for="form.slug">
            {{ form.label }}
            <small v-if="form.required" style="color: red;">*</small>
          </label>

          <!-- Inputan tipe text -->
          <input
            v-if="form.tipe_inputan.tipe_inputan === 'text'"
            type="text"
            class="form-control"
            :id="form.slug"
            :placeholder="form.placeholder || form.label"
            v-model="formData[form.slug]"
            :required="form.required"
          />

          <!-- Inputan tipe number -->
          <input
            v-if="form.tipe_inputan.tipe_inputan === 'number'"
            type="number"
            class="form-control"
            :id="form.slug"
            :placeholder="form.placeholder || form.label"
            v-model="formData[form.slug]"
            :required="form.required"
          />

          <!-- Inputan tipe select -->
          <select
            v-if="form.tipe_inputan.tipe_inputan === 'select'"
            class="form-control"
            :id="form.slug"
            v-model="formData[form.slug]"
            :required="form.required"
          >
            <option disabled value="">{{ form.placeholder || form.label }}</option>
            <option v-for="option in form.options" :key="option.option_id" :value="option.option_id">
              {{ option.value }}
            </option>
          </select>

          <!-- Inputan tipe checkbox -->
  <!-- Inputan tipe checkbox -->
  <div v-if="form.tipe_inputan.tipe_inputan === 'checkbox'">
            <div v-for="option in form.options" :key="option.option_id" class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                :id="form.slug + '-' + option.option_id"
                :value="option.option_id"
                :true-value="option.option_id" 
                :false-value="null"           
                v-model="formData[form.slug]" 
              />
              
              <label :for="form.slug + '-' + option.option_id" class="form-check-label">
                {{ option.value }}
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</fieldset>

  <fieldset>    
  <legend style="font-size: larger;">Pilih Fasilitas <small style="color: red;">*</small></legend>
  <div class="form-check" v-for="fasilitas in forms.fasilitas" :key="fasilitas.fasilitas_id">
    <input
      type="checkbox"
      class="form-check-input"
      :id="'fasilitas_' + fasilitas.fasilitas_id"
      :value="fasilitas.fasilitas_id"
      v-model="formData.fasilitas_id"
    />
    <label class="form-check-label" :for="'fasilitas_' + fasilitas.fasilitas_id">
      {{ fasilitas.nama_fasilitas }}
    </label>
  </div>


  <!-- Checkbox for "Punya Ruang Pertemuan" -->
<div 
  class="form-group form-check"
  v-if="unitUsahaData && unitUsahaData.tipe_usaha && unitUsahaData.tipe_usaha.tipe_usaha === 'Hotel'"
>
  <input
    v-model="formData.punya_ruang_pertemuan"
    type="checkbox"
    class="form-check-input"
    id="punya_ruang_pertemuan"
    name="punya_ruang_pertemuan"
  />
  <label class="form-check-label" for="punya_ruang_pertemuan">
    Ruang Pertemuan
  </label>
</div>

<!-- Meeting Room Input Table (Shown if "punya_ruang_pertemuan" is true) -->
<div v-if="formData.punya_ruang_pertemuan">
  <table class="table table-striped">
    <thead>
      <tr>
        <td>
          <div class="form-group">
            <select v-model="new_ruang_pertemuans.jenis_ruang_pertemuan" class="form-control">
              <option value="Ruang Pertemuan">Ruang Pertemuan</option>
              <option value="Meeting Room">Meeting Room</option>
              <option value="Ball Room">Ball Room</option>
            </select>
            <small v-if="validationErrors.jenis_ruang_pertemuan" class="text-danger">*Ruang pertemuan harus diisi*</small>
          </div>
        </td>
        <td>
          <div class="form-group">
            <input v-model="new_ruang_pertemuans.nama_ruang_pertemuan" type="text" class="form-control" placeholder="Nama Ruang">
            <small v-if="validationErrors.nama_ruang_pertemuan" class="text-danger">*Nama ruang harus diisi*</small>
          </div>
        </td>
        <td>
          <div class="form-group">
            <input v-model="new_ruang_pertemuans.kapasitas_ruang_pertemuan" type="number" class="form-control" placeholder="Kapasitas Ruang">
            <small v-if="validationErrors.kapasitas_ruang_pertemuan" class="text-danger">*Kapasitas harus diisi*</small>
          </div>
        </td>
        <td>
          <button type="button" class="btn" @click="addRuangPertemuan">
            <i class="bi bi-plus-square text-success" style="font-size: larger;"></i>
          </button>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(ruang, index) in ruang_pertemuans" :key="index">
        <td>{{ ruang.jenis_ruang_pertemuan }}</td>
        <td>{{ ruang.nama_ruang_pertemuan }}</td>
        <td>{{ ruang.kapasitas_ruang_pertemuan }}</td>
        <td>
           <button type="button" class="btn btn-danger" @click="hapusRuang(index)">
                            Hapus
                          </button>
                        </td>
      </tr>
    </tbody>
  </table>
</div>

          </fieldset>


<!-- Loading spinner -->
<div v-if="loading" class="loading-overlay">
      <div class="spinner"></div>
      <p>Tunggu Sebentar ...</p>
    </div>

        <br>
          <button :disabled="loading" type="submit" class="btn btn-primary">SIMPAN</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "CreateLkuComponent",
  data() {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 4;
    const endYear = currentYear;
    return {
      loading: false, // Add this state
      forms: {
        fasilitas: [],
        single_forms: [],
        grup_forms: [],
        sub_grup_forms: [],
      },
      formData: {
        tahun_pelaporan: '',
        tanggal_pelaporan: '',
        luas_tanah: '',
        luas_bangunan: '',
        semester: '',
        jumlah_lantai: '',
        punya_ruang_pertemuan:null,
        pengunjung_bulan_1: '',
        pengunjung_bulan_2: '',
        pengunjung_bulan_3: '',
        pengunjung_bulan_4: '',
        pengunjung_bulan_5: '',
        pengunjung_bulan_6: '',
        ruang_pertemuans: [],
        fasilitas_id: [],  // Initialize as an empty array
        single_forms: [],
        grup_forms: [],
        sub_grup_forms: [],
      },
      new_ruang_pertemuans: {
        jenis_ruang_pertemuan: '',
        nama_ruang_pertemuan: '',
        kapasitas_ruang_pertemuan: ''
      },
     
      ruang_pertemuans: [],
      validationErrors: {
        jenis_ruang_pertemuan: false,
        nama_ruang_pertemuan: false,
        kapasitas_ruang_pertemuan: false
      },
      selectedSingleForms: {},
      laporanExists: false,
      unitUsahaData: null,  // Initialize to null to avoid undefined errors
      years: Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i),
      today: new Date().toISOString().split('T')[0],
      
    };
  },

  created() {
    this.fetchFasilitas();
  },

  methods: {
     
  goBack() {
    this.$router.go(-1);  // This will navigate to the previous page.
  },
    checkExistingLaporan() {
  if (!this.unitUsahaData) {
    console.log('unitUsahaData is null or undefined');
    return;
  }

  const tahun_pelaporan = Number(this.formData.tahun_pelaporan);
  const semester = Number(this.formData.semester);

  console.log('Selected tahun_pelaporan:', tahun_pelaporan);
  console.log('Selected semester:', semester);
  console.log('Existing laporan:', this.unitUsahaData.laporan_lku);

  // Check if the combination of tahun_pelaporan and semester exists
  this.laporanExists = this.unitUsahaData.laporan_lku.some((laporan) => {
    const laporanTahun = Number(laporan.tahun_pelaporan);
    const laporanSemester = Number(laporan.semester);

    const match = laporanTahun === tahun_pelaporan && laporanSemester === semester;
    console.log(`Checking laporan: ${laporan.tahun_pelaporan} - Semester ${laporan.semester}`, 'Match:', match);

    return match;
  });

  console.log('laporanExists:', this.laporanExists);
},


async fetchFasilitas() {
  try {
    const token = localStorage.getItem('token');
    const id = this.$route.query.id;

    if (!id) {
      console.error('ID is missing in the route query parameters.');
      return;
    }

    // Fetching unit usaha data
    const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/unit-usaha/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    this.unitUsahaData = response.data.data;

    // // Extracting `m_tipe_usaha_id` from `unitUsahaData.tipe_usaha`
    // const tipeUsaha = this.unitUsahaData?.tipe_usaha;
    // const m_tipe_usaha_id = tipeUsaha?.m_tipe_usaha_id;

    // if (!m_tipe_usaha_id) {
    //   console.error('m_tipe_usaha_id is missing in unitUsahaData.');
    //   return;
    // }

    // Fetching fasilitas forms data
    const fasilitasResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/form/lapor-lku/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const fasilitasData = fasilitasResponse.data;
    if (fasilitasData.status && fasilitasData.code === 200) {
      this.forms = { ...fasilitasData.forms };

      // Initialize formData for checkboxes in single_forms
      this.forms.single_forms.forEach(form => {
        if (form.tipe_inputan.tipe_inputan === 'checkbox') {
          this.$set(this.formData, form.slug, []); // Initialize checkbox as an empty array
        }
      });

      // Initialize formData for checkboxes in sub_grup_forms if any
      this.forms.sub_grup_forms.forEach(grup => {
        grup.sub_grup.forEach(subGrup => {
          subGrup.forms.forEach(form => {
            if (form.tipe_inputan.tipe_inputan === 'checkbox') {
              this.$set(this.formData, form.slug, []); // Initialize checkbox as array
            }
          });
        });
      });
    } else {
      console.error('Data not valid:', fasilitasData);
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
},

   // Format date to dd/mm/yyyy
formatDate(date) {
  if (!date) return "";
  // Check if the date is in yyyy-mm-dd format and convert it to dd/mm/yyyy
  if (date.includes("-")) {
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  }
  // If the date is already in dd/mm/yyyy format, return it as is
  return date;
},

// Handle form submission
async submitForm() {
  this.loading = true; // Set loading to true when submission starts
  try {
    const token = localStorage.getItem('token');
    const id = this.$route.query.id;

    if (!id) {
      console.error('ID is missing in the route query parameters.');
      return;
    }

    // Format the tanggal_pelaporan field before submission
    if (this.formData.tanggal_pelaporan) {
      this.formData.tanggal_pelaporan = this.formatDate(this.formData.tanggal_pelaporan);
    } 
     // If `punya_ruang_pertemuan` is null, do not send `ruang_pertemuans`
    if (this.formData.punya_ruang_pertemuan === null || this.formData.punya_ruang_pertemuan === '') {
      delete this.formData.ruang_pertemuans;  // Do not send ruang_pertemuans
    } else {
      // Only include `ruang_pertemuans` if `punya_ruang_pertemuan` is not null and there are entries
      if (this.ruang_pertemuans.length > 0) {
        this.formData.ruang_pertemuans = this.ruang_pertemuans;
      } else {
        this.formData.ruang_pertemuans = null;  // Set to null if there are no meeting rooms
      }
    }
    // Properly format the Axios request
    const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/lapor-lku/${id}`, this.formData, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log('Server response:', response); // Use 'response' in some way
    this.$router.push({ path: `/dashboard/unit-usaha/lku?id=${id}`, query: { success: true } });
  } catch (error) {
    if (error.response && error.response.data && error.response.data.code === 403) {
        // Display the specific error message from the API in a toast
        this.$bvToast.toast(error.response.data.errors, {
          variant: 'danger',
          solid: true,
        });
      } else {
        // Fallback for other errors
        console.error('Error submitting form', error);
        this.$bvToast.toast('Error input data', {
          variant: 'danger',
          solid: true,
        });
      }
  }finally {
    this.loading = false; // Reset loading state after submission completes
  }
},
    // Add a new meeting room row
    addRuangPertemuan() {
      if (!this.new_ruang_pertemuans.jenis_ruang_pertemuan || !this.new_ruang_pertemuans.nama_ruang_pertemuan || !this.new_ruang_pertemuans.kapasitas_ruang_pertemuan) {
        // Handle validation error
        this.validationErrors = {
          jenis_ruang_pertemuan: !this.new_ruang_pertemuans.jenis_ruang_pertemuan,
          nama_ruang_pertemuan: !this.new_ruang_pertemuans.nama_ruang_pertemuan,
          kapasitas_ruang_pertemuan: !this.new_ruang_pertemuans.kapasitas_ruang_pertemuan,
        };
        return;
      }
      // Push new meeting room data
      this.ruang_pertemuans.push({ ...this.new_ruang_pertemuans });
      this.new_ruang_pertemuans = { jenis_ruang_pertemuan: '', nama_ruang_pertemuan: '', kapasitas_ruang_pertemuan: '' }; // Reset input fields
    },

    hapusRuang(index) {

this.ruang_pertemuans.splice(index, 1);

},
  },
  
 
  // Fetch initial data when the component is mounted
  
};
</script>
<style scoped>
/* Add your scoped styles here if needed */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #28a745;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinner arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>