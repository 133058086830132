<template>
  <div class="container">
    <div class="card o-hidden border-0 shadow-lg my-5 col-lg-12 mx-auto">
      <div class="card-body p-0">
        <!-- Nested Row within Card Body -->
        <div class="row">
          <div class="col-lg-6 d-none d-lg-block bg-register-image">
            <img src="../../assets/images/register.png" alt="Register Image" class="img-fluid" style="height: 100%;">
          </div>
          <div class="col-lg-6">
            <div class="p-5">
              <div class="text-center">
                <h1 class="h4 text-gray-900 mb-4"><b>Buat Akun Baru</b></h1>
              </div>

              <!-- Display error message if any -->
              <div v-if="errorMessage" class="alert alert-danger alert-dismissible fade show" role="alert">
                {{ errorMessage }}
              </div>
              
              <!-- Display success message if any -->
              <div v-if="successMessage" class="alert alert-success alert-dismissible fade show" role="alert">
                {{ successMessage }}
              </div>

              <form @submit.prevent="handleSubmit" id="register_form">
                <!-- Form Fields -->
                <div class="form-group">
                  <label class="text-muted">NIB (Nomor Induk Berusaha)</label>
                  <input
                    type="number"
                    class="form-control form-control-user"
                    v-model="formData.nib"
                    :class="{'is-invalid': errors.nib}"
                    placeholder="Masukan NIB (Nomor Induk Berusaha) Anda"
                    required
                    maxlength="13"
                     @input="checkLength"
                  >
                  <div v-if="errors.nib" class="invalid-feedback">
                    {{ errors.nib }}
                  </div>
                </div>

                <div class="form-group">
                  <label class="text-muted">Nama PT</label>
                  <div class="input-group">
                    <select
                      class="form-select"
                      v-model="formData.jenis_perusahaan"
                      required
                    >
                      <option value="">Jenis Perusahaan</option>
                      <option v-for="j in jenisPerusahaan" :key="j.id" :value="j.id">{{ j.tipe_usaha }}</option>
                    </select>
                    <input
                      type="text"
                      class="form-control form-control-user"
                      v-model="formData.name"
                      :class="{'is-invalid': errors.name}"
                      placeholder="Masukan Nama PT Anda"
                      required
                    >
                  </div>
                  <div v-if="errors.name" class="invalid-feedback">
                    {{ errors.name }}
                  </div>
                </div>

                <div class="form-group">
                  <label class="text-muted">Email</label>
                  <input
                    type="email"
                    class="form-control form-control-user"
                    v-model="formData.email"
                    :class="{'is-invalid': errors.email}"
                    placeholder="Masukan Alamat Email Anda"
                    required
                  >
                  <div v-if="errors.email" class="invalid-feedback">
                    {{ errors.email }}
                  </div>
                </div>

                <div class="form-group">
                  <label class="text-muted">Password</label>
                  <input
                    type="password"
                    class="form-control form-control-user"
                    v-model="formData.password"
                    :class="{'is-invalid': errors.password}"
                    placeholder="Masukkan 8+ karakter password anda"
                    required
                  >
                  <div v-if="errors.password" class="invalid-feedback">
                    {{ errors.password }}
                  </div>
                </div>

                <div class="form-group">
                  <label class="text-muted">Konfirmasi Password</label>
                  <input
                    type="password"
                    class="form-control form-control-user"
                    v-model="formData.password2"
                    :class="{'is-invalid': errors.password2}"
                    placeholder="Masukkan ulang 8+ karakter password anda"
                    required
                  >
                  <div v-if="errors.password2" class="invalid-feedback">
                    {{ errors.password2 }}
                  </div>
                </div>

                <div class="form-group form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="formData.syarat"
                    id="termsCheck"
                    required
                  >
                  <label class="form-check-label text-muted" for="termsCheck">
                    Saya setuju dengan <a class="text-primary" href="#">Syarat dan Ketentuan</a>
                  </label>
                </div>

                <button type="submit" class="btn btn-primary btn-user btn-block">
                  <b>Daftar Akun</b>
                </button>
              </form>
              <hr>
              <div class="text-center">
                <p> Saya sudah memiliki akun. <a class="text-primary" href="/login"><b>Masuk !</b></a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
   
    <!-- Success Modal -->
    <b-modal v-model="showSuccessModal" title="Berhasil!" ok-title="Tutup"  @ok="handleClose"
    @hidden="handleClose">
      {{ successMessage }}
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';
import { BModal } from 'bootstrap-vue';

export default {
  name: 'RegisterComponent',
  components: {
    BModal
  },
  data() {
    return {
      formData: {
        nib: '',
        jenis_perusahaan: '',
        name: '',
        email: '',
        password: '',
        password2: '',
        syarat: false,
      },
      errors: {},
      errorMessage: '',
      successMessage: '',
      showSuccessModal: false, // Control the modal visibility
      jenisPerusahaan: [], // Initialize as empty array
    };
  },
  async created() {
    await this.fetchJenisPerusahaan();
  },
  methods: {
    checkLength(event) {
    const maxLength = 13;

    if (this.formData.nib && this.formData.nib.toString().length > maxLength) {
      this.formData.nib = this.formData.nib.toString().slice(0, maxLength);
    }

    // Ensure no input greater than max length
    event.target.value = this.formData.nib;
  },
    async fetchJenisPerusahaan() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/tipe-perusahaan`);
        this.jenisPerusahaan = response.data.data; // Extract the data array from the response
      } catch (error) {
        console.error('Failed to fetch company types:', error);
        this.errorMessage = 'Failed to load company types';
      }
    },
    validateForm() {
      this.errors = {}; // Reset errors
      this.errorMessage = ''; // Reset error message
      this.successMessage = ''; // Reset success message

      // Validate passwords
      if (this.formData.password !== this.formData.password2) {
        this.errors.password2 = 'Password tidak sama';
      } else if (this.formData.password.length < 8) {
        this.errors.password = 'Password harus minimal 8 karakter';
      }

      // Validate NIB
      if (!/^\d{13}$/.test(this.formData.nib)) {
        this.errors.nib = 'NIB harus terdiri dari 13 angka';
      }

      // Validate other required fields
      if (!this.formData.name) {
        this.errors.name = 'Nama harus diisi';
      }
      if (!this.formData.email) {
        this.errors.email = 'Email harus diisi';
      }
      if (!this.formData.syarat) {
        this.errors.syarat = 'Anda harus menyetujui syarat dan ketentuan';
      }

      // Check if there are errors
      return Object.keys(this.errors).length === 0;
    },
    async handleSubmit() {
      if (!this.validateForm()) {
        return;
      }

      try {
        // Post registration data to the API
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/register`, this.formData);
        
        if (response.status === 200) {
          // Show success message and trigger modal
          this.successMessage = 'Anda berhasil registrasi !';
          this.showSuccessModal = true; // Show modal

          // Clear form data on success
          this.formData = {
            nib: '',
            jenis_perusahaan: '',
            name: '',
            email: '',
            password: '',
            password2: '',
            syarat: false,
          };

          // Optionally, redirect after some time or user action
          // this.$router.push('/login'); // Redirect to login page after successful registration
        } else {
          this.errorMessage = 'Registrasi gagal. Mohon coba lagi.';
        }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          // Handle validation errors from the API
          this.errors = error.response.data.errors;

          // Generate user-friendly error messages without quotes and brackets
          this.errorMessage = error.response.data.message;

          // Process the errors object to remove brackets and quotes
          for (const key in this.errors) {
            if (this.errors[key] && Array.isArray(this.errors[key])) {
              this.errors[key] = this.errors[key].join(', ');
            }
          }
        } else {
          console.error('Error during registration:', error);
          this.errorMessage = 'Registrasi tidak berhasil';
        }
      }
    },
    handleClose() {
      this.$router.push('/login'); // Redirect to login page when modal is closed
    }
  }
}
</script>

<style scoped>
.container {
  margin-top: 50px;
}

.card {
  border-radius: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 2rem;
}

.bg-register-image {
  background-color: #f8f9fa;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.form-control {
  border-radius: 2rem;
  padding: 1rem;
  font-size: 1rem;
}

.form-control-user {
  border-radius: 2rem;
}

.btn-user {
  border-radius: 2rem;
}

.text-muted {
  color: #6c757d !important;
}

.text-primary {
  color: #5e72e4 !important;
}

.text-gray-900 {
  color: #3a3b45 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinner arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
