<template>
    <div class="container-fluid mt-3">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 font-weight-bold text-primary">Laporan Kegiatan Usaha</h6>
          <button
      class="btn btn-primary mr-2 d-none d-lg-inline-block lg-show"
      type="button"
      @click="exportData"
    >
      Export <i class="bi-file-earmark mr-2"></i>
    </button>
        
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <h6 class="m-0 font-weight-bold text-primary"></h6>
          <center>
           
            <img :src="require('../../assets/images/logoenjoy.png')"  alt="Logo" />
            <br><br>
            <h4 style="color: black;">Laporan Kegiatan Usaha</h4>
            <br>
          </center>
        
            <div class="table-responsive">
                <table class="table table-bordered" width="100%" cellspacing="0">
                
                <tbody>
                    <tr>
                    <td>Nama Usaha</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.nama_unit_usaha || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Alamat Usaha</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.alamat || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Kelurahan</td>
                    <td v-if="unitUsahaData && unitUsahaData.kelurahan">{{ unitUsahaData.kelurahan.kelurahan || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Kecamatan</td>
                    <td v-if="unitUsahaData && unitUsahaData.kecamatan">{{ unitUsahaData.kecamatan.kecamatan || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Kotamadya/Kabupaten</td>
                    <td v-if="unitUsahaData && unitUsahaData.kabupaten">{{ unitUsahaData.kabupaten.kabupaten || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>NOPD</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.nopd || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Luas Tanah</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.luas_tanah || 'Data not available' }} m<sup>2</sup></td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Luas Bangunan</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.luas_bangunan || 'Data not available' }} m<sup>2</sup></td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Nama Penanggung Jawab</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.penanggung_jawab || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Jabatan Penanggung Jawab</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.jabatan_penanggung_jawab || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>No. HP Penanggung Jawab</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.nohp_penanggung_jawab || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>No. Telepon Kantor</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.telpon_kantor || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Email</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.email || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Website</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.website || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Skala Usaha</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.skala_usaha || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                    <tr>
                    <td>Instagram</td>
                    <td v-if="unitUsahaData">{{ unitUsahaData.instagram || 'Data not available' }}</td>
                    <td v-else>Data not available</td>
                    </tr>
                </tbody>
                </table>
            </div>

              <legend class="mt-3">Data Tenaga Kerja</legend>

              <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Jumlah Karyawan Pria TKI</th>
                                <th>Jumlah Karyawan Wanita TKI</th>
                                <th>Jumlah Karyawan Pria TKA</th>
                                <th>Jumlah Karyawan Wanita TKA</th>

                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td v-if="unitUsahaData">{{ unitUsahaData.tki_pria || 'Data not available'}}</td>
                                <td v-else>Data not available</td>
                                <td v-if="unitUsahaData">{{ unitUsahaData.tki_wanita || 'Data not available' }}</td>
                                <td v-else>Data not available</td>
                                <td v-if="unitUsahaData">{{ unitUsahaData.tka_pria || 'Data not available' }}</td>
                                <td v-else>Data not available</td>
                                <td v-if="unitUsahaData">{{ unitUsahaData.tka_wanita || 'Data not available'}}</td>
                                <td v-else>Data not available</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
           
            <legend class="mt-3">Data Legalitas Usaha</legend>
          
            <div class="table-responsive">
    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
        <tbody>
            <tr>
                <td>Jenis Izin</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.jenis_izin || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Nomor Izin</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.nomor_izin || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Tanggal Terbit Izin</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.tanggal_terbit_izin || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Klasifikasi Resiko</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.klasifikasi_resiko || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Tipe Usaha</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.tipe_usaha.tipe_usaha || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Sertifikat Laik Sehat ( SLS )</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.sertifikat_laik_sehat === 1 ? 'Ada' : 'Tidak Ada' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Masa Berlaku SLS </td>
                <td v-if="unitUsahaData">{{ unitUsahaData.masa_berlaku_laik_sehat || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Sertifikat Standar Usaha ( SSU )</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.sertifikat_standar_usaha === 1 ? 'Ada' : 'Tidak Ada' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Masa Berlaku SSU</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.masa_berlaku_ssu || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
            <tr>
                <td>Penerbit SSU</td>
                <td v-if="unitUsahaData">{{ unitUsahaData.penerbit_ssu || 'Data not available' }}</td>
                <td v-else>Data not available</td>
            </tr>
        </tbody>
    </table>
</div>


            <legend class="mt-3">Data Pengurus</legend>   
            <table class="table table-bordered">
    <thead>
        <tr>
            <th>Jabatan</th>
            <th>Nama Pengurus</th>
        </tr>
    </thead>
    <tbody v-if="unitUsahaData && unitUsahaData.pengurus && unitUsahaData.pengurus.length">
        <tr v-for="(pengurus, index) in unitUsahaData.pengurus" :key="index">
            <td>{{ pengurus.jabatan || 'Data not available' }}</td>
            <td>{{ pengurus.nama_pengurus || 'Data not available' }}</td>
        </tr>
    </tbody>
    <tbody v-else>
        <tr>
            <td colspan="2">Data not available</td>
        </tr>
    </tbody>
</table>



<legend class="mt-3">Pemberkasan</legend>   
<div class="table-responsive">
    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
        <thead>
            <tr>
                <th>Jenis Berkas</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Sertifikat Laik Sehat (SLS)</td>
                <td>
                    <a 
                        v-if="unitUsahaData && unitUsahaData.sls_file" 
                        class="text-info mx-2" 
                        :href="`${url}/storage/${unitUsahaData.sls_file}`" 
                        title="Download Berkas" 
                        target="_blank" 
                        data-bs-toggle="tooltip" 
                        data-bs-placement="top">
                        <i class="bi bi-file-earmark-arrow-down-fill text-success" style="font-size: 1.5rem;"></i>
                    </a>
                    <span v-else>Tidak Ada</span>
                </td>
            </tr>

            <tr>
                <td>Sertifikat Standar Usaha (SSU)</td>
                <td>
                    <a 
                        v-if="unitUsahaData && unitUsahaData.ssu_file" 
                        class="text-info mx-2" 
                        :href="`${url}/storage/${unitUsahaData.ssu_file}`" 
                        title="Download Berkas" 
                        target="_blank" 
                        data-bs-toggle="tooltip" 
                        data-bs-placement="top">
                        <i class="bi bi-file-earmark-arrow-down-fill text-success" style="font-size: 1.5rem;"></i>
                    </a>
                    <span v-else>Tidak Ada</span>
                </td>
            </tr>

            <tr>
                <td>Berkas Perizinan</td>
                <td>
                    <a 
                        v-if="unitUsahaData && unitUsahaData.file_perizinan" 
                        class="text-info mx-2" 
                        :href="`${url}/storage/${unitUsahaData.file_perizinan}`" 
                        title="Download Berkas" 
                        target="_blank" 
                        data-bs-toggle="tooltip" 
                        data-bs-placement="top">
                        <i class="bi bi-file-earmark-arrow-down-fill text-success" style="font-size: 1.5rem;"></i>
                    </a>
                    <span v-else>Tidak Ada</span>
                </td>
            </tr>

            <tr>
                <td>Akta Pendirian</td>
                <td>
                    <a 
                        v-if="unitUsahaData && unitUsahaData.akta_pendirian_file" 
                        class="text-info mx-2" 
                        :href="`${url}/storage/${unitUsahaData.akta_pendirian_file}`" 
                        title="Download Berkas" 
                        target="_blank" 
                        data-bs-toggle="tooltip" 
                        data-bs-placement="top">
                        <i class="bi bi-file-earmark-arrow-down-fill text-success" style="font-size: 1.5rem;"></i>
                    </a>
                    <span v-else>Tidak Ada</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>


             <legend class="mt-3">Riwayat Laporan Kegiatan Usaha ( LKU )</legend>  
             <div class="table-responsive">
    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
        <thead>
            <tr>
                <th>Tahun Pelaporan</th>
                <th>Periode</th>
                <th>Tanggal Pelaporan</th>
                <th>Perbaikan</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <template v-if="unitUsahaData && unitUsahaData.laporan_lku.length">
                <tr v-for="lku in unitUsahaData.laporan_lku" :key="lku.tlku_id">
                    <td>{{ lku.tahun_pelaporan }}</td>
                    <td>Semester {{ lku.semester }}</td>
                    <td>{{ lku.tanggal_pelaporan }}</td>
                    <td>
                        <span v-if="lku.status_lku === 0" class="badge rounded-pill bg-primary">Draft</span>
                        <span v-else-if="lku.status_lku === 1" class="badge rounded-pill bg-success">Disetujui</span>
                        <span v-else-if="lku.status_lku === 2" class="badge rounded-pill bg-danger">Revisi</span>
                    </td>
                    <td>Perbaikan ke {{ lku.perbaikan }}</td>
                    <td>
                        <div class="text-info mx-2" @click="viewLku(lku.tlku_id)" title="Lihat Data">
                            <i class="bi bi-zoom-in"></i> Lihat Data
                        </div>
                    </td>
                </tr>
            </template>
            <template v-else>
                <tr>
                    <td colspan="6" class="text-center">Data tidak tersedia</td>
                </tr>
            </template>
        </tbody>
    </table>
</div>

          </div>
         
      </div>
    </div>
</template>

<script>
import axios from 'axios'; // Ensure axios is imported

export default {
  name: "ViewUsahaComponent",

  data() {
    return {
      url:`${process.env.VUE_APP_API_BASE_URL}`,
      token: localStorage.getItem('token') || 'Token tidak ditemukan',
      unitUsahaData: null, // Initialize to null before the data is fetched
      errorMessage: '', // Initialize error message as an empty string
    };
  },
  
  async mounted() {
    try {
      const token = localStorage.getItem('token');

      // Check if the token exists
      if (!token) {
        this.errorMessage = 'Authentication token is missing. Please log in again.';
        console.error(this.errorMessage);
        return;
      }

      const id = this.$route.query.id;

      // Check if ID is available
      if (!id) {
        this.errorMessage = 'ID is missing in the route query parameters.';
        console.error(this.errorMessage);
        return;
      }

      // API call
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/unit-usaha/${id}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      // Ensure response.data has the expected structure
      if (response.data && response.data.data) {
        this.unitUsahaData = response.data.data;
        console.log('Fetched data:', this.unitUsahaData);
      } else {
        this.errorMessage = 'Unexpected response structure from the API.';
        console.error(this.errorMessage, response.data);
      }
      
    } catch (error) {
      this.errorMessage = 'Error fetching unit usaha data: ' + (error.response?.data?.message || error.message);
      console.error('Error:', error);
    }
    
  },
  methods: {
    async exportData() {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      this.errorMessage = 'Authentication token is missing. Please log in again.';
      console.error(this.errorMessage);
      return;
    }

    const id = this.$route.query.id;
    if (!id) {
      this.errorMessage = 'ID is missing in the route query parameters.';
      console.error(this.errorMessage);
      return;
    }

    const url = `${process.env.VUE_APP_API_BASE_URL}/api/export/unit-usaha/${id}`;

    // Create a form and submit it to open the PDF in a new tab
    const form = document.createElement('form');
    form.method = 'GET'; // or 'GET', based on API requirements
    form.action = url;
    form.target = '_blank'; // Open in a new tab
    this.randomCode = Array.from({ length: 16 }, () =>
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'.charAt(Math.floor(Math.random() * 62))
      ).join('');

      // Create an input to store the token
      const inputToken = document.createElement('input');
      inputToken.type = 'hidden';
      inputToken.name = this.randomCode; // Set the random code as the name
      inputToken.value = this.randomCode; // Set the random code as the value
    // Append the input to the form
    form.appendChild(inputToken);

    // Add the form to the document and submit it
    document.body.appendChild(form);
    form.submit();

    // Remove the form after submission
    form.remove();

    this.successMessage = 'PDF opened in a new tab successfully!';

  } catch (apiError) {
    console.error('API request error:', apiError);
    this.errorMessage = 'Failed to export data. Please try again.';
  }
},

  viewLku(tlku_id) {
    
    // Redirect to the view page with the provided tlku_id
    this.$router.push({
      path: '/dashboard/unit-usaha/lku/view',
      query: { tlku_id: tlku_id }
    });
  },
},
}
</script>


<style scoped>
/* Add some basic styling if needed */
.alert {
  color: red;
}
</style>
