<template>
  <nav class="navbar navbar-expand topbar mb-4 static-top shadow" style="background-color:#05274B;">
    <!-- Sidebar Toggle (Topbar) -->


    <ul class="navbar-nav ml-auto">
      <div class="topbar-divider d-none d-sm-block"></div>

      <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
        <template #button-content>
          <span class="text-white">{{ userName }}<i class="bi bi-person ml-2"></i></span>
        </template>
        <b-dropdown-item>
          <router-link
  to="/dashboard/setting"
  style="white-space: nowrap; display: inline-block; color: inherit; text-decoration: none;"
>
  <i class="bi bi-person mr-2"></i>Ubah Password
</router-link>

      </b-dropdown-item>

        <b-dropdown-item @click="logout"><i class="bi bi-box-arrow-in-left mr-2"></i>Log Out</b-dropdown-item>
      </b-dropdown>
    </ul>
  </nav>
</template>

<script>
import axios from 'axios';

export default {
  name: "TopbarComponent",
  data() {
    return {
      searchQuery: '',
      userName: '', // Initialize as an empty string
    };
  },
  created() {
    this.fetchUserName();
  },
  methods: {
       
 
    async fetchUserName() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/me`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        this.userName = response.data.data.name;
      } catch (error) {
        console.error('Error fetching user name:', error);
      }
    },
    async logout() {
      try {
        const response = await axios({
          method: 'get',
          url: `${process.env.VUE_APP_API_BASE_URL}/api/logout`,
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.status === 200) {
          localStorage.removeItem('token');
          window.location.href = '/login';
        } else {
          console.error('Logout failed:', response);
        }
      } catch (error) {
        if (error.response && error.response.status === 405) {
          console.error('Method not allowed. Check the API method.');
        } else {
          console.error('Error during logout:', error);
        }
      }
    }
  }
};
</script>

<style scoped>
/* Ensure the input and button are the same height */
.input-group .form-control,
.input-group .btn {
  height: 38px; /* Match the height to the default Bootstrap size or adjust as needed */
}

.input-group .btn {
  border-radius: 25px; /* Adjust border-radius to match the input field */
}

.text-white {
  color: #fff !important;
}
</style>
