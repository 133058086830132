<template>
  <div class="lku-form">
   
   

    <div class="container-fluid mt-3">
      <b-button variant="outline-primary" class="mb-3" @click="goBack"><i class="bi bi-arrow-left-circle-fill"></i> Kembali</b-button>
      <br>
    <div class="card shadow mb-4">
      <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">Formulir Data Laporan</h6>
      </div>
      <div class="card-body">
         <!-- Display error message -->
    <div v-if="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>

    <!-- Display success message -->
    <div v-if="successMessage" class="alert alert-success">
      {{ successMessage }}
    </div>
    <!-- LKU Form -->
    <form @submit.prevent="updateLku">
      

      <div class="form-group">
        <label for="tahun_pelaporan">Tahun Pelaporan <small style="color: red;">*</small></label>
        <input 
          type="text" 
          class="form-control" 
          id="tahun_pelaporan" 
          v-model="lkuData.tahun_pelaporan" 
          readonly
          required
        />
      </div>
       
      <div class="form-group">
            <label for="semester">Semester <small style="color: red;">*</small></label>
            <select class="form-control" id="semester" v-model="lkuData.semester" required readonly>
              <option disabled value="">Pilih Semester</option>
              <option value="1">Semester 1</option>
              <option value="2">Semester 2</option>
            </select>
          </div>

          <div class="form-group">
            <label for="tanggal_pelaporan">Tanggal Pelaporan <small style="color: red;">*</small></label>
            <input
              type="text"
              class="form-control"
              id="tanggal_pelaporan"
              v-model="lkuData.tanggal_pelaporan"
              name="tanggal_pelaporan"
              readonly
              required
            />
          </div>

          
          <fieldset>
            <legend style="font-size:larger;">Jumlah Pengunjung<small style="color: red;">*</small></legend>
            <div class="form-row">
              <div class="form-group col-lg-4" v-for="(bulan, index) in 6" :key="index">
                <label :for="'pengunjung_bulan_' + (index + 1)">Pengunjung Bulan {{ index + 1 }} <small style="color: red;">*</small></label>
                <input
                  type="number"
                  class="form-control"
                  :id="'pengunjung_bulan_' + (index + 1)"
                  v-model="lkuData['pengunjung_bulan_' + (index + 1)]"
                  placeholder="Masukan Jumlah Pengunjung"
                  required
                />
              </div>
            </div>
          </fieldset>

          <div class="form-group">
            <label for="luas_tanah">Luas Tanah (m²) <small style="color: red;">*</small></label>
            <input
              type="number"
              step="0.01"
              class="form-control"
              id="luas_tanah"
              placeholder="Contoh: 104,5"
              v-model="lkuData.luas_tanah"
              required
            />
          </div>

          <div class="form-group">
            <label for="luas_bangunan">Luas Bangunan (m²) <small style="color: red;">*</small></label>
            <input
              type="number"
              step="0.01"
              class="form-control"
              id="luas_bangunan"
              placeholder="Contoh: 104,5"
              v-model="lkuData.luas_bangunan"
              required
            />
          </div>

          <div class="form-group">
            <label for="jumlah_lantai">Jumlah Lantai <small style="color: red;">*</small></label>
            <input
              type="number"
              class="form-control"
              id="jumlah_lantai"
              placeholder="Masukan Jumlah Lantai"
              v-model="lkuData.jumlah_lantai"
              required
            />
          </div>

          <div v-for="form in lkuData.single_forms" :key="form.slug">
        <label :for="form.slug">{{ form.label }}
          <small v-if="form.required" style="color: red;">*</small>
        </label>

        <!-- Select Input -->
        <select
          v-if="form.tipe_inputan.tipe_inputan === 'select'"
          class="form-control"
          :id="form.slug"
          v-model="form[form.slug]"
        >
          <option disabled value="">{{ form.palceholder }}</option>
          <option v-for="option in form.options" :key="option.option_id" :value="option.option_id">
            {{ option.value }}
          </option>
        </select>

        
      <!-- Number Input -->
      <input
        v-if="form.tipe_inputan.tipe_inputan === 'number'"
        type="number"
        class="form-control"
        :id="form.slug"
        :placeholder="form.placeholder"
        v-model="form[form.slug]"
        />
        <!-- Text Input -->
        <input
        v-if="form.tipe_inputan.tipe_inputan === 'text'"
        type="text"
        class="form-control"
        :id="form.slug"
        :placeholder="form.placeholder"
        v-model="form[form.slug]"
      />
   <!-- Checkbox Input -->
<div v-if="form.tipe_inputan.tipe_inputan === 'checkbox'">
  <div v-for="option in form.options" :key="option.option_id" class="form-check">
    <input
      type="checkbox"
      class="form-check-input"
      :id="`${form.slug}_${option.option_id}`"
      :value="option.option_id"
      :checked="isChecked(form.slug, option.option_id)" 
      @change="toggleCheckbox(form.slug, option.option_id)" 
    />
    <label class="form-check-label" :for="`${form.slug}_${option.option_id}`">
      {{ option.value }}
    </label>
  </div>
</div>


  </div>
    


<!-- <fieldset>
 
  <div v-if="lkuData.grup_forms.length">
    <div v-for="group in lkuData.grup_forms" :key="group.grup_id" class="form-group mt-2">
      <legend style="font-size: larger;">{{ group.nama_grup }}</legend>

      <div v-for="form in group.forms" :key="form.single_form_id">
        <label :for="form.slug">
          {{ form.label }} 
          <small v-if="form.required" style="color: red;">* </small>
        </label>

      
        <input
          v-if="form.tipe_inputan.tipe_inputan === 'text'"
          type="text"
          class="form-control"
          :id="form.slug"
          :placeholder="form.placeholder"
          v-model="lkuData.grup_forms[0].forms.find(f => f.slug === form.slug)[form.slug]"
          :required="form.required"
        />

      
        <select
          v-if="form.tipe_inputan.tipe_inputan === 'select'"
          class="form-control"
          :id="form.slug"
          v-model="lkuData.grup_forms[0].forms.find(f => f.slug === form.slug)[form.slug]"
          :required="form.required"
        >
       
          <option disabled value="">{{ form.placeholder }}</option>

      
          <option v-for="option in form.options" :key="option.option_id" :value="option.option_id">
            {{ option.value }}
          </option>
        </select>
      </div>
    </div>
  </div>
</fieldset> -->
<fieldset>
    <!-- Form Grup -->
    <div v-if="lkuData.grup_forms.length">
      <div v-for="group in lkuData.grup_forms" :key="group.grup_id" class="form-group mt-2">
        <legend style="font-size: larger;">{{ group.nama_grup }}</legend>

        <div v-for="form in group.forms" :key="form.slug">
          <label :for="form.slug">
            {{ form.label }} 
            <small v-if="form.required" style="color: red;">* </small>
          </label>

          <!-- Inputan tipe text -->
          <input
            v-if="form.tipe_inputan.tipe_inputan === 'text'"
            type="text"
            class="form-control"
            :id="form.slug"
            :placeholder="form.placeholder"
            v-model="lkuData.grup_forms[0].forms.find(f => f.slug === form.slug)[form.slug]" 
         />
         <input
            v-if="form.tipe_inputan.tipe_inputan === 'number'"
            type="text"
            class="form-control"
            :id="form.slug"
            :placeholder="form.placeholder"
            v-model="lkuData.grup_forms[0].forms.find(f => f.slug === form.slug)[form.slug]" 
         />


          <!-- Inputan tipe select -->
          <select
            v-if="form.tipe_inputan.tipe_inputan === 'select'"
            class="form-control"
            :id="form.slug"
            v-model="lkuData.grup_forms[0].forms.find(f => f.slug === form.slug)[form.slug]" 
          
          >
            <!-- Placeholder option -->
            <option disabled value="">{{ form.placeholder }}</option>

            <!-- List of options -->
            <option v-for="option in form.options" :key="option.option_id" :value="option.option_id">
              {{ option.value }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </fieldset>
<fieldset>
  <!-- Sub Group -->
  <div v-if="forms.sub_grup_forms.length">
    <div v-for="grup in forms.sub_grup_forms" :key="grup.grup_id" class="form-group mt-2">
      <legend style="font-size: larger;">{{ grup.nama_grup }}</legend>

      <div v-for="subGrup in grup.sub_grup" :key="subGrup.sub_grup_id" class="sub-group mt-2">
        <legend style="font-size: larger;">{{ subGrup.nama_sub_grup }}</legend>

        <div v-for="form in subGrup.forms" :key="form.slug">
          <label :for="form.slug">
            {{ form.label }}
            <small v-if="form.required" style="color: red;">*</small>
          </label>

          <!-- Inputan tipe text -->
          <input
            v-if="form.tipe_inputan.tipe_inputan === 'text'"
            type="text"
            class="form-control"
            :id="form.slug"
            :placeholder="form.placeholder || form.label"
            v-model="formData[form.slug]"
            :required="form.required"
          />

          <!-- Inputan tipe number -->
          <input
            v-if="form.tipe_inputan.tipe_inputan === 'number'"
            type="number"
            class="form-control"
            :id="form.slug"
            :placeholder="form.placeholder || form.label"
            v-model="formData[form.slug]"
            :required="form.required"
          />

          <!-- Inputan tipe select -->
          <select
            v-if="form.tipe_inputan.tipe_inputan === 'select'"
            class="form-control"
            :id="form.slug"
            v-model="lkuData.sub_grup_forms
    .find(grup => grup.grup_id === 2).sub_grup
    .find(subGrup => subGrup.sub_grup_id === 1).forms
    .find(form => form.slug === 'sub_group_select_1yO3YYWDB3m')['sub_group_select_1yO3YYWDB3m']"

            :required="form.required"
          >
            <option disabled value="">{{ form.placeholder || form.label }}</option>
            <option v-for="option in form.options" :key="option.option_id" :value="option.option_id">
              {{ option.value }}
            </option>
          </select>
<!-- Checkbox Input -->
<div v-if="form.tipe_inputan.tipe_inputan === 'checkbox'">
  <div v-for="option in form.options" :key="option.option_id" class="form-check">
    <input
      type="checkbox"
      class="form-check-input"
      :id="`${form.slug}_${option.option_id}`"
      :value="option.option_id"
      :checked="isChecked(form.slug, option.option_id)"
      @change="toggleCheckbox(form.slug, option.option_id)"
    />
    <label class="form-check-label" :for="`${form.slug}_${option.option_id}`">
      {{ option.value }}
    </label>
  </div>
</div>



        </div>
      </div>
    </div>
  </div>
</fieldset>

  <fieldset>  

    <legend style="font-size: larger;">Pilih Fasilitas<small style="color: red;">*</small></legend>
<div class="form-check" v-for="fasilitas in forms.fasilitas" :key="fasilitas.fasilitas_id">
  <input
    type="checkbox"
    class="form-check-input"
    :id="'fasilitas_' + fasilitas.fasilitas_id"
    :value="fasilitas.fasilitas_id"
    :checked="isFasilitasSelected(fasilitas.fasilitas_id)" 
    @change="toggleFasilitas(fasilitas)" 
  />
  <label class="form-check-label" :for="'fasilitas_' + fasilitas.fasilitas_id">
    {{ fasilitas.nama_fasilitas }}
  </label>
</div>
  
 


  <!-- Checkbox for "Punya Ruang Pertemuan" -->
<div 
  class="form-group form-check"
  v-if="lkus && lkus.unit_usaha && lkus.unit_usaha.tipe_usaha === 'Hotel'"
>
  <input
    v-model="lkuData.punya_ruang_pertemuan"
    type="checkbox"
    class="form-check-input"
    id="punya_ruang_pertemuan"
    name="punya_ruang_pertemuan"
  />
  <label class="form-check-label" for="punya_ruang_pertemuan">
    Ruang Pertemuan
  </label>
</div>

<!-- Meeting Room Input Table (Shown if "punya_ruang_pertemuan" is true) -->
<div v-if="lkuData.punya_ruang_pertemuan">
  <table class="table table-striped">
    <thead>
      <tr>
        <td>
          <div class="form-group">
            <select v-model="new_ruang_pertemuans.jenis_ruang_pertemuan" class="form-control">
              <option value="Ruang Pertemuan">Ruang Pertemuan</option>
              <option value="Meeting Room">Meeting Room</option>
              <option value="Ball Room">Ball Room</option>
            </select>
            <small v-if="validationErrors.jenis_ruang_pertemuan" class="text-danger">*Ruang pertemuan harus diisi*</small>
          </div>
        </td>
        <td>
          <div class="form-group">
            <input v-model="new_ruang_pertemuans.nama_ruang_pertemuan" type="text" class="form-control" placeholder="Nama Ruang">
            <small v-if="validationErrors.nama_ruang_pertemuan" class="text-danger">*Nama ruang harus diisi*</small>
          </div>
        </td>
        <td>
          <div class="form-group">
            <input v-model="new_ruang_pertemuans.kapasitas_ruang_pertemuan" type="number" class="form-control" placeholder="Kapasitas Ruang">
            <small v-if="validationErrors.kapasitas_ruang_pertemuan" class="text-danger">*Kapasitas harus diisi*</small>
          </div>
        </td>
        <td>
          <button type="button" class="btn" @click="addRuangPertemuan">
            <i class="bi bi-plus-square text-success" style="font-size: larger;"></i>
          </button>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(ruang, index) in lkuData.ruang_pertemuans" :key="index">
        <td>{{ ruang.jenis_ruang_pertemuan }}</td>
        <td>{{ ruang.nama_ruang_pertemuan }}</td>
        <td>{{ ruang.kapasitas_ruang_pertemuan }}</td>
        <td>{{ ruang.ruang_pertemuan_id }}</td>
        <td>
           <button type="button" class="btn btn-danger" @click="hapusRuang(ruang.ruang_pertemuan_id)">
                            Hapus
                          </button>
                        </td>
      </tr>
    </tbody>
  </table>
</div>

          </fieldset>


      <button type="submit" class="btn btn-primary">SIMPAN</button>
    </form>
    </div>
    </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      forms: {
        fasilitas: [],
        single_forms: [],
        grup_forms: [],
        sub_grup_forms: [],
      },
      lkuData: { punya_ruang_pertemuan: null
        
      }, // Holds the LKU data, including dynamic form fields
      isLoading: false, // Controls the loading spinner
      errorMessage: '', // Error message
      successMessage: '', // Success message
      formData: {
        punya_ruang_pertemuan: null,
        ruang_pertemuans: [],
        fasilitas_id: [], // Initialize as an empty array
        single_forms: [],
        grup_forms: [],
        sub_grup_forms: [],
      },
      new_ruang_pertemuans: {
        jenis_ruang_pertemuan: '',
        nama_ruang_pertemuan: '',
        kapasitas_ruang_pertemuan: '',
      },
      ruang_pertemuans: [],
      validationErrors: {
        jenis_ruang_pertemuan: false,
        nama_ruang_pertemuan: false,
        kapasitas_ruang_pertemuan: false,
      }, // Holds dynamic form data, like checkboxes
    };
  },

  methods: {
     // Check if a facility is already selected
  isFasilitasSelected(fasilitas_id) {
    return this.lkuData.fasilitas.some(fasilitas => fasilitas.fasilitas_id == fasilitas_id);
  },

  // Toggle the selection of a facility
  toggleFasilitas(fasilitas) {
    const index = this.lkuData.fasilitas.findIndex(f => f.fasilitas_id == fasilitas.fasilitas_id);
    if (index > -1) {
      // If already selected, remove from the array
      this.lkuData.fasilitas.splice(index, 1);
    } else {
      // If not selected, add to the array
      this.lkuData.fasilitas.push({ fasilitas_id: fasilitas.fasilitas_id, nama_fasilitas: fasilitas.nama_fasilitas });
    }
  }, 
  
  isChecked(slug, optionId) {
    // console.log('Tes option : ', optionId);
    // console.log('Tes slug :', slug);
    
    const PilihLagi = this.lkuData.single_forms;
    // Find the object with the given slug
    const tagHotel = PilihLagi.find(form => form.slug === slug);

    if (tagHotel) {
        // Extract the option IDs
        const optionIDs = tagHotel.tag_hotel_RA7GiMYsFN.map(tag => parseInt(tag.optioin_id));

        // Check if the optionId is in the optionIDs array
        const isSelected = optionIDs.includes(optionId);
        
        // Log the result
        // console.log(optionIDs); // Output: [37, 38]
        // console.log('Is selected:', isSelected); // true or false

        return isSelected;
    }
    
    // Return false if tagHotel is not found
    return false;
  },
  // isChecked(slug, optionId) {
  //   const selectedOptions = this.formData[slug] || []; // Retrieve the selected options
  //   console.log(`Checking if optionId ${optionId} is selected for slug ${slug}:`, selectedOptions); // Log selected options

  //   const isSelected = selectedOptions.some(option => option.optioin_id === optionId.toString()); // Check if optionId matches
  //   console.log(`Is optionId ${optionId} selected?`, isSelected); // Log the result
  //   return isSelected;
  // },

  // Helper method to retrieve option value by id
  getOptionValue(option_id) {
    const option = this.form.options.find(opt => opt.option_id === option_id);
    console.log(`Retrieving value for option_id ${option_id}:`, option); // Log the found option

    const value = option ? option.value : '';
    console.log(`Value for option_id ${option_id} is:`, value); // Log the result
    return value;
  },
  // Toggle checkbox selection
  toggleCheckbox(slug, optionId) {
    const selectedOptions = this.formData[slug] || []; // Ensure array exists
    const optionIdInt = parseInt(optionId); // Convert optionId to an integer
    const index = selectedOptions.indexOf(optionIdInt);

    if (index === -1) {
      // Add the selected option_id if it's not already in the array
      selectedOptions.push(optionIdInt);
    } else {
      // Remove the selected option_id if it's already in the array
      selectedOptions.splice(index, 1);
    }

    // Update formData with the modified selected options
    this.$set(this.formData, slug, selectedOptions);

    // Log the current state of the selected options
    console.log(`Updated ${slug} options:`, this.formData[slug]);
  },
  
  
    goBack() {
      this.$router.go(-1);  // This will navigate to the previous page.
    },
    // Fetch Fasilitas Forms Data
    async fetchFasilitas() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication token is missing. Please log in again.');
        }

        const tlku_id = this.$route.query.tlku_id;
        if (!tlku_id) {
          throw new Error('tlku_id is missing in the route query parameters.');
        }

        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/lapor-lku/${tlku_id}`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status !== 200) {
          throw new Error(`Failed to fetch LKU data. Status code: ${response.status}`);
        }

        this.lkus = response.data.data; // Assign LKU data
       
        const unit_usaha_id = this.lkus.unit_usaha.unit_usaha_id;
        if (!unit_usaha_id) {
          throw new Error('unit_usaha_id is missing in unitUsahaData.');
        }

        const fasilitasResponse = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/form/lapor-lku/${unit_usaha_id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (fasilitasResponse.status !== 200 || !fasilitasResponse.data.status || fasilitasResponse.data.code !== 200) {
          throw new Error('Failed to fetch fasilitas forms data.');
        }

        this.forms = { ...fasilitasResponse.data.forms };
          // Initialize formData for checkboxes in single_forms
      this.forms.single_forms.forEach(form => {
        if (form.tipe_inputan.tipe_inputan === 'checkbox') {
          this.$set(this.formData, form.slug, []); // Initialize checkbox as an empty array
        }
      });

        // Initialize formData for checkboxes
        this.forms.sub_grup_forms.forEach(grup => {
          grup.sub_grup.forEach(subGrup => {
            subGrup.forms.forEach(form => {
              if (form.tipe_inputan.tipe_inputan === 'checkbox') {
                this.$set(this.formData, form.slug, []); // Initialize checkbox as array
              }
            });
          });
        });

      } catch (error) {
        console.error('Error fetching data:', error.message || error);
        this.errorMessage = error.message || 'An unknown error occurred while fetching data.';
      }
    },

    async fetchLkuData() {
      this.isLoading = true;
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication token is missing. Please log in again.');
        }

        const tlku_id = this.$route.query.tlku_id;
        if (!tlku_id) {
          throw new Error('tlku_id is missing in the route query parameters.');
        }

        const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/lapor-lku/${tlku_id}`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.data && response.data.data) {
          this.lkuData = response.data.data; // Store LKU data in lkuData
          // console.log('LKU fetched data:', this.lkuData);
          // console.log('Group forms:', this.lkuData.grup_forms); // Log the grup_forms
        } else {
          throw new Error('LKU data is not available.');
        }
      } catch (error) {
        this.errorMessage = error.response?.data?.message || error.message || 'Failed to fetch LKU data.';
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
// Update LKU data
async updateLku() {
  this.isLoading = true;
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Authentication token is missing. Please log in again.');
    }

    const tlku_id = this.$route.query.tlku_id;
    if (!tlku_id) {
      throw new Error('tlku_id is missing in the route query parameters.');
    }

    // Prepare data for PUT
    const formDataToUpdate = {};
   // Process single_forms
   if (Array.isArray(this.lkuData.single_forms)) {
      this.lkuData.single_forms.forEach(form => {
        const value = this.lkuData.single_forms.find(f => f.slug === form.slug);
        if (value) {
          // Check if the slug is for a checkbox and handle it
          if (form.tipe_inputan.tipe_inputan === 'checkbox') {
            // Assuming formData holds the selected checkbox option_ids
            formDataToUpdate[form.slug] = this.formData[form.slug] || []; // Default to empty array if undefined
          } else {
            formDataToUpdate[form.slug] = value[form.slug];
          }
        }
      });
    }

    // Process group_forms
    if (Array.isArray(this.lkuData.grup_forms)) {
      this.lkuData.grup_forms.forEach(group => {
        if (Array.isArray(group.forms)) {
          group.forms.forEach(form => {
            const value = group.forms.find(f => f.slug === form.slug);
            if (value) {
              formDataToUpdate[value.slug] = value[value.slug];
            }
          });
        }
      });
    }

    // Prepare data from lkuData
    Object.keys(this.lkuData).forEach(key => {
      if (key !== 'single_forms' && key !== 'grup_forms' && key !== 'ruang_pertemuans'&& key !== 'fasilitas') {
        formDataToUpdate[key] = this.lkuData[key];
      }
    });

    // Handle ruang_pertemuans
    if (this.lkuData.punya_ruang_pertemuan === null || this.lkuData.punya_ruang_pertemuan === '') {
      delete formDataToUpdate.ruang_pertemuans;
    } else {
      const validRuangPertemuans = this.lkuData.ruang_pertemuans.filter(item => !item.ruang_pertemuan_id);
      if (validRuangPertemuans.length > 0) {
        formDataToUpdate.ruang_pertemuans = validRuangPertemuans; // Only include valid rooms
      } else {
        formDataToUpdate.ruang_pertemuans = null; // If no valid rooms, set to null
      }
    }


    // Handle fasilitas (convert fasilitas_id to integers)
    if (Array.isArray(this.lkuData.fasilitas)) {
      formDataToUpdate.fasilitas_id = this.lkuData.fasilitas.map(fasilitas => parseInt(fasilitas.fasilitas_id, 10));
    }
   
    // // Log for debugging
    // console.log('Data yang akan dikirim:', formDataToUpdate);
  
    // Send a PUT request to update the LKU data
    const response = await axios.put(
      `${process.env.VUE_APP_API_BASE_URL}/api/lapor-lku/${tlku_id}`,
      formDataToUpdate,
      {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
    );
    const id = this.lkuData.unit_usaha.unit_usaha_id;
    console.log('Server response:', response); // Use 'response' in some way
    this.$router.push({ path: `/dashboard/unit-usaha/lku?id=${id}`, query: { updated: true } });
  } catch (error) {
    if (error.response && error.response.data && error.response.data.code === 403) {
        // Display the specific error message from the API in a toast
        this.$bvToast.toast(error.response.data.errors, {
          variant: 'danger',
          solid: true,
        });
      } else {
        // Fallback for other errors
        console.error('Error submitting form', error);
        this.$bvToast.toast('Error input data', {
          variant: 'danger',
          solid: true,
        });
      }
  }finally {
    this.loading = false; // Reset loading state after submission completes
  }
},
addRuangPertemuan() {
  if (!this.new_ruang_pertemuans.jenis_ruang_pertemuan || !this.new_ruang_pertemuans.nama_ruang_pertemuan || !this.new_ruang_pertemuans.kapasitas_ruang_pertemuan) {
    // Handle validation error
    this.validationErrors = {
      jenis_ruang_pertemuan: !this.new_ruang_pertemuans.jenis_ruang_pertemuan,
      nama_ruang_pertemuan: !this.new_ruang_pertemuans.nama_ruang_pertemuan,
      kapasitas_ruang_pertemuan: !this.new_ruang_pertemuans.kapasitas_ruang_pertemuan,
    };
    return;
  }
  // Push new meeting room data into lkuData.ruang_pertemuans
  this.lkuData.ruang_pertemuans.push({ ...this.new_ruang_pertemuans });
  // Reset new_ruang_pertemuans and validation errors
  this.new_ruang_pertemuans = { jenis_ruang_pertemuan: '', nama_ruang_pertemuan: '', kapasitas_ruang_pertemuan: '' };
  this.validationErrors = { jenis_ruang_pertemuan: false, nama_ruang_pertemuan: false, kapasitas_ruang_pertemuan: false };
},

hapusRuang(ruang_pertemuan_id) {
  // Log the ID to ensure correct debugging information
  console.log(`Attempting to delete meeting room with ruang_pertemuan_id: ${ruang_pertemuan_id}`);

  // If ID is undefined, handle the deletion locally
  if (typeof ruang_pertemuan_id === 'undefined' || ruang_pertemuan_id === 'undefined') {
    // Find the index of the room with the undefined ID
    const index = this.lkuData.ruang_pertemuans.findIndex(ruang_pertemuans => ruang_pertemuans.ruang_pertemuan_id === undefined);
   

    // Check if the room with the undefined ID exists locally
    if (index !== -1) {
      // Remove the room at the found index
      this.lkuData.ruang_pertemuans.splice(index, 1);

      // Log current room data for debugging
      this.logRuangData();

      console.log('Meeting room with undefined ID has been removed locally.');
    } else {
      console.error('Meeting room with undefined ID not found locally.');
    }
  } else {
    // Handle deletion when ID is valid and provided
    this.deleteRuangFromServer(ruang_pertemuan_id);
  }
},

async deleteRuangFromServer(ruang_pertemuan_id) {
  // Find the index of the room with the matching ID
  const index = this.lkuData.ruang_pertemuans.findIndex(ruang_pertemuans => ruang_pertemuans.ruang_pertemuan_id === ruang_pertemuan_id);
  
  // Check if the room with the provided ID exists locally
  if (index !== -1) {
    try {
      const token = localStorage.getItem('token');
      // Make the DELETE request to the server
      await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/api/pertemuan/${ruang_pertemuan_id}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'multipart/form-data',
        },
      });

      // Remove the room at the found index locally
      this.lkuData.ruang_pertemuans.splice(index, 1);

      // Log current room data for debugging
      this.logRuangData();

      console.log(`Meeting room with ruang_pertemuan_id ${ruang_pertemuan_id} has been successfully deleted from the server and local data.`);
    } catch (error) {
      // Handle error if the delete request fails
      if (error.response && error.response.status === 404) {
        console.warn(`Meeting room with ruang_pertemuan_id ${ruang_pertemuan_id} not found on the server, removing locally.`);
        this.ruang_pertemuans.splice(index, 1); // Remove locally if not on the server
        this.logRuangData();
      } else {
        console.error(`Failed to delete meeting room with ruang_pertemuan_id ${ruang_pertemuan_id}:`, error);
      }
    }
  } else {
    console.error(`Meeting room with ruang_pertemuan_id ${ruang_pertemuan_id} not found in local data.`);
  }
},

// Method to log the current room data for debugging purposes
logRuangData() {
  console.log('Current meeting rooms:', this.ruang_pertemuans);
},
  

    // Process formData after fetching form structure
    initializeFormData() {
      this.lkuData.single_forms.forEach((form) => {
        this.$set(this.formData, form.slug, form[form.slug]);
      });
    },
  },

  created() {
    this.fetchLkuData();
    this.fetchFasilitas();
  },
  computed: {
  filteredRuangPertemuans() {
    return this.lkuData.ruang_pertemuans.filter(room => !room.ruang_pertemuan_id);
  }
}
};
</script>




<style scoped>
.loading-spinner {
  text-align: center;
}

.alert {
  margin-top: 20px;
}
</style>
