<template>
  <div class="content">
    
    <div class="container">
      

    
      <div class="main-component">

    <slot></slot> <!-- This is where the ChartComponent will be rendered -->
    </div>
    </div>
   <!-- Footer -->
   <footer class="sticky-footer bg-white">
                <div class="container my-auto">
                    <div class="copyright text-center my-auto">
                        <span>Copyright &copy; 2024 Dinas Pariwisata dan Ekonomi Kreatif Daerah Khusus Jakarta</span>
                    </div>
                </div>
            </footer>
            <!-- End of Footer -->
  </div>

</template>

<script>
export default {
  name: "MainComponent"
  
};
</script>

<style scoped>
.content {
  padding: 15px;
  background-color: #f8f9fe;
  min-height: 100vh;
}

.text-xs{
  font-size:.7rem};
.main-component {
  /* Styles for MainComponent */
}
.sticky-footer{padding:2rem 0;flex-shrink:0}footer.sticky-footer .copyright{line-height:1;font-size:.8rem}body.sidebar-toggled footer.sticky-footer{width:100%}
</style>
